import React from "react";
import { navigate } from "gatsby";
import loadable from "@loadable/component";
import "../../styles/post.scss";
const Parser = loadable(() => import("../parser.js"));

const Post = (props) => {
  const handleClick = (event) => {
    if (event.target.nodeName.toLowerCase() === "a") {
      return;
    }
    navigate(props.uri);
  };
  return (<article className={ props.className } style={{ ...props.style, order: props.index }} onClick={ handleClick }>
    <Parser
      log={true}
      id={ props.id }
      slug={ props.slug }
      uri={ props.uri }
      pagination={ props.pagination }
      date={ props.date }
      excerpt={ props.excerpt }
      title={ props.title }
      oneLastNews={ props.oneLastNews }
      coverImage={ props.featuredImage ? props.featuredImage.node : undefined }
      terms={{
        nodes: props.categories.nodes,
        asList: false,
        asLink: true
      }}>{ props.children }</Parser>
  </article>);
};

export default Post;
